import $ from 'jquery'

const handlers = [
    // data table row click
    () => {
        $(document).on('click', '.v-data-table tbody tr', function() {
            $(this).toggleClass('grey darken-3');
        });
    }
]

handlers.forEach(handler => {
    handler.call(this);
});
